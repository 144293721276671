<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="Contract__title"
            :float-layout="true"
            :enable-download="false"
            :html-to-pdf-options="htmlToPdfOptions"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <v-col>
                            <v-row no-gutters class="align-center">
                                <v-col class="col-9-5 d-flex flex-column">
                                    <span>{{ $t('message.date') + ": " + Ipa__date }}</span>
                                </v-col>
                                <v-col class="col-2-5 text-right">
                                    <img
                                        :src="appLogo"
                                        class="mb-3 mr-3"
                                        width="130px"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>{{ $t('message.dearCustomer') + "," }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-7 pb-5">
                                <v-col cols="12" style="font-size: 1.85em; font-weight: bolder">{{ $t('message.importPermitForContract') + " " + Contract__title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-3">
                                <v-col cols="12">{{ ipaConstants.IpaNote1 }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-3">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.consignorDetails') }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.rocRobNo') }}</v-col>
                                <v-col cols="7">{{ $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.companyName') }}</v-col>
                                <v-col cols="7">{{ Supplier__title }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.companyAddress') }}</v-col>
                                <v-col cols="7" class="d-flex flex-column" v-if="Ipa__use_alt_address != 1">
                                    <span>{{ Supplier__address1 }}</span>
                                    <span>{{ Supplier__address2 }}</span>
                                    <span>{{ Supplier__address3 }}</span>
                                    <span>{{ Supplier__city + ' ' + Supplier__postcode}}</span>
                                    <span>{{ Supplier__state }}</span>
                                    <span>{{ Supplier__country }}</span>
                                </v-col>
                                <v-col cols="7" class="d-flex flex-column" v-if="Ipa__use_alt_address == 1">
                                    <span v-html="Ipa__alt_address ? nl2br(Ipa__alt_address) : ''"></span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-3">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.applicationDetails') }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.modeOfTransport') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaTransportMode }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.voyageNumber') }}</v-col>
                                <v-col cols="7">{{ Contract__shipmentstatus_text }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.purpose') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaPurpose }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.traderReferenceNumber') }}</v-col>
                                <v-col cols="7">{{ Contract__title }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.countryOfOrigin') }}</v-col>
                                <v-col cols="7">{{ Origin__country }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.placeOfOrigin') }}</v-col>
                                <v-col cols="7">{{ Loadingport__title }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.consignedFrom') }}</v-col>
                                <v-col cols="7">{{ Consignment__country }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.eta') }}</v-col>
                                <v-col cols="7">{{ Contract__etadate }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.etd') }}</v-col>
                                <v-col cols="7">{{ Contract__etddate }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.customsStationCode') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaCustomsStationCode }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.entryPoint') }}</v-col>
                                <v-col cols="7">{{ Entryport__title }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.applicationRemarks') }}</v-col>
                                <v-col cols="7">{{ Entryport__title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-3">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.additionalDetails') }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.category') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaCategory }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.gmo') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaGmo }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.methodOfDelivery') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaDeliveryMethod }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.packageType') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaPackType }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-3">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.itemDetails') }}</v-col>
                            </v-row>
                            <template v-if="Items.length > 0">
                                <template v-for="(item, index) in Items">
                                    <v-row no-gutters>
                                        <v-col cols="1">{{ $t('message.item') + ' ' + (index + 1).toString() }}</v-col>
                                        <v-col cols="4">{{ $t('message.commonName') }}</v-col>
                                        <v-col cols="7">{{ item['Species']['title'] }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="1"></v-col>
                                        <v-col cols="5">{{ $t('message.scientificName') }}</v-col>
                                        <v-col cols="7">{{ item['Species']['latin_name'] }}</v-col>
                                    </v-row>
                                </template>
                            </template>
                            <template v-else>
                                <v-row no-gutters>
                                    <v-col cols="5">{{ $t('message.commonName') }}</v-col>
                                    <v-col cols="7">{{ Species__title }}</v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="5">{{ $t('message.scientificName') }}</v-col>
                                    <v-col cols="7">{{ Species__latin_name }}</v-col>
                                </v-row>
                            </template>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.descriptionForm') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaDescriptionForm }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.unitDescription') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaUnitOfDescription }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.citesNo') }}</v-col>
                                <v-col cols="7">{{ $t('message.na') }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.plantQuarantineQuantity') }}</v-col>
                                <v-col cols="7">{{ Ipa__quarantine_qty + " " + Ipa__uofm }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.tariffQty') }}</v-col>
                                <v-col cols="7">{{ Ipa__tariff_qty + " " + Ipa__uofm }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.tariffCode') }}</v-col>
                                <v-col cols="7">{{ Species__hscode }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.unitOfMeasurement') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaUnitOfMeasurement }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="5">{{ $t('message.tariffDescription') }}</v-col>
                                <v-col cols="7">{{ ipaConstants.IpaTariffDescription }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-5">
                                <v-col>{{ ipaConstants.IpaNote2 }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-5">
                                <v-col>{{ $t('message.thankYou') + "," }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-5">
                                <v-col class="d-flex flex-column">
                                    <div class="font-weight-bold">{{ $t('message.appTimber') }}</div>
                                    <div><span>{{ $t('message.operations') }} - </span><span class="font-italic">{{ $t('message.weHereServeYou') }}</span></div>
                                    <div>{{ $t('message.hqMalaysia') }}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import {formatDate, numberFormat, nl2br, log} from "../../../helpers/helpers";
    import {api} from "../../../api";
    import {ipaConstants, uofm} from "../../../store/modules/appic/constants"
    import AppConfig from "Constants/AppConfig";
    import VueHtml2pdf from 'vue-html2pdf';

    export default {
        name: "PrintIpaNewBlob",
        props: ['ipaId'],
        components: {VueHtml2pdf},
        data() {
            return {
                appLogo: AppConfig.appSessionLogo,
                htmlToPdfOptions: {
                    image: {
                        type: 'jpeg',
                        quality: 0.70
                    },
                    html2canvas: {
                        scale: 1.5
                    }
                },
                ipaConstants: ipaConstants,
                loaded: false,
                uofmQty: uofm.qty,
                Consignment__country: null,
                Contract__etadate: null,
                Contract__etddate: null,
                Contract__shipmentstatus_text: null,
                Contract__title: null,
                Entryport__title: null,
                Ipa__alt_address: null,
                Ipa__date: null,
                Ipa__ipasent_date: null,
                Ipa__measurement_id: 1,
                Ipa__quarantine_qty: null,
                Ipa__tariff_qty: null,
                Ipa__use_alt_address: 0,
                Items: [],
                Loadingport__title: null,
                Origin__country: null,
                Species__title: null,
                Species__hscode: null,
                Species__hscode_veneer: null,
                Species__latin_name: null,
                Supplier__title: null,
                Supplier__address1: null,
                Supplier__address2: null,
                Supplier__address3: null,
                Supplier__city: null,
                Supplier__country: null,
                Supplier__country_id: null,
                Supplier__gstno: null,
                Supplier__postcode: null,
                Supplier__state: null,
            }
        },
        computed: {
            Ipa__uofm () {
                return this.uofmQty.find((u) => u.Measurement.id == this.Ipa__measurement_id)?.Measurement?.title
            }
        },
        methods: {
            async beforeDownload({html2pdf, options, pdfContent}) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    let reader = new FileReader()
                    let _this = this
                    reader.readAsDataURL(pdf.output('blob'));
                    reader.onloadend = function () {
                        let file = reader.result;
                        api
                            .post('/ipas/pdf', {
                                pdf: {
                                    ipa_title: _this.Contract__title,
                                    ipa_id: _this.ipaId,
                                    file: file
                                },
                            })
                            .then((response) => {
                                if (response.data.status == 'success') {
                                    _this.$emit('pdf-uploaded', response.data.url)
                                } else {
                                    _this.$emit('pdf-not-uploaded')
                                }
                            })
                            .catch(() => {
                                _this.$emit('pdf-not-uploaded')
                            })
                    }
                })
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            loadIpaById(ipa_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/ipas/" + ipa_id,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                self[key] = response.data.data[0][key]
                            }
                            resolve(ipa_id)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{

                        })
                })
            },
            nl2br
        },
        created () {
            this.$emit('pdf-uploading')
            this.loadIpaById(this.ipaId)
                .then(()=>{
                    this.loaded = true
                    this.$refs.html2Pdf.generatePdf()
                })
                .catch(() => {
                    this.$emit('pdf-not-uploaded')
                })
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .paper-a4 {
        height: 1065px;
        margin-left: 75px;
        margin-top: 35px;
        background-color: #FFFFFF !important;
        color: #000000 !important;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:12px !important;
        font-size:9pt !important;
        padding:2px !important;
        background-color: #FFFFFF !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
    * {
        text-rendering: geometricprecision !important;
    }
</style>